var Harmony=window.Harmony={JSONP:"JSONP",CORS:"CORS",AUSTRALIA:"au",NEW_ZEALAND:"nz",AUSOTS:"AUSOTS",AUPAF:"AUPAF",GNAF:"GNAF",NZPAF:"NZPAF",ID:"id",ATTRIBUTES:"attributes",EID:"eid",ADDRESS:"address",BUILDING_NAME:"buildingName",STATE_TOWN_CITY:"stateTownCity",LOCALITY:"locality",POSTAL:"postal",POSTCODE:"postcode",POSTCODE_LOCALITY:"postcodeLocality",SOURCE_OF_TRUTH:"sourceOfTruth",STATE:"state",STREET_2:"street2",STREET:"street",STREET_ADDRESS:"streetAddress",STREET_NUMBER:"streetNumber",SUBDWELLING:"subdwelling",
SUBURB:"suburb",TOWN_CITY:"townCity",FLOOR_LEVEL_TYPE:"floorLevelType",FLOOR_LEVEL_NUMBER:"floorLevelNumber",FLAT_UNIT_TYPE:"flatUnitType",FLAT_UNIT_NUMBER:"flatUnitNumber",LOT_NUMBER:"lotNumber",STREET_NAME:"streetName",STREET_TYPE:"streetType",STREET_SUFFIX:"streetSuffix",FLOOR_LEVEL_TYPES:"floorLevelTypes",POSTAL_TYPES:"postalTypes",STATE_TYPES:"stateTypes",STREET_TYPES:"streetTypes",STREET_SUFFIX_TYPES:"streetSuffixTypes",UNIT_FLAT_TYPES:"unitFlatTypes",SUCCESS:"SUCCESS",ERROR:"ERROR",ENV_DEVELOPMENT:"http://dev.hosted.mastersoftgroup.com",
ENV_TEST:"http://test.hosted.mastersoftgroup.com",ENV_PREVIEW:"http://preview.hosted.mastersoftgroup.com",ENV_PREVIEW_SSL:"https://preview-hosted.mastersoftgroup.com",_protocol:"",_baseUrl:"https://hosted.mastersoftgroup.com",_locale:"",_password:"",_username:"",_featureOptions:null,address:function(b,a,c){Harmony._lookup("/address",b,a,c)},buildingName:function(b,a,c){Harmony._lookup("/buildingName",b,a,c)},country:function(b,a,c){Harmony._lookup("/country",b,a,c)},init:function(b,a,c){Harmony._username=
b;Harmony._password=a;Harmony._locale=c;Harmony._protocol=Harmony.CORS},locality:function(b,a,c){Harmony._lookup("/locality",b,a,c)},postal:function(b,a,c){Harmony._lookup("/postal",b,a,c)},postcode:function(b,a,c){Harmony._lookup("/postcode",b,a,c)},state:function(b,a,c){Harmony._lookup("/state",b,a,c)},street:function(b,a,c){Harmony._lookup("/street",b,a,c)},streetNumber:function(b,a,c){Harmony._lookup("/streetNumber",b,a,c)},subdwelling:function(b,a,c){Harmony._lookup("/subdwelling",b,a,c)},suburb:function(b,
a,c){Harmony._lookup("/suburb",b,a,c)},townCity:function(b,a,c){Harmony._lookup("/townCity",b,a,c)},transaction:function(b,a){Harmony._lookup("/transaction",null,b,a)},useEnv:function(b){Harmony._baseUrl=b},useProtocol:function(b){Harmony._protocol=b},useFeatureOptions:function(b){Harmony._featureOptions=b},_getAjaxObject:function(){var b=null;return b=window.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP")},_getAuthorisation:function(){return"Basic "+Base64.encode(Harmony._username+
":"+Harmony._password)},_getJSONP:function(b,a,c){var d=Harmony._baseUrl+"/harmony/rest/"+Harmony._locale+b,e=a.payload[0],f=document.createElement("script");b="jsonCallback_"+b.replace(/\//g,"_");"function"!==typeof c&&(c=function(){});window[b]=c;a.Authorization=Harmony._getAuthorisation();for(var k in e)a[k]=e[k];delete a.payload;if("undefined"!==typeof a.featureOptions&&"object"===typeof a.featureOptions){c="";for(var h in a.featureOptions)c+=h+":"+a.featureOptions[h]+";";a.featureOptions=c}d+=
"?callback\x3d"+b+"\x26"+Harmony._getQueryString(a);f.src=d;document.getElementsByTagName("head")[0].appendChild(f)},_getQueryString:function(b){var a=null,c="";for(a in b)""!=c&&(c+="\x26"),c+=a+"\x3d"+b[a];return c},_lookup:function(b,a,c,d){a={payload:[a]};null!=c&&(a.sourceOfTruth=c);Harmony._featureOptions&&(a.featureOptions=Harmony._featureOptions);Harmony._protocol==Harmony.JSONP?Harmony._getJSONP(b,a,d):Harmony._protocol==Harmony.CORS&&Harmony._postJSON(b,a,d)},_postJSON:function(b,a,c){var d;
b=Harmony._baseUrl+"/harmony/rest/"+Harmony._locale+b;var e=Harmony._getAjaxObject();e.open("POST",b,!0);e.setRequestHeader("Content-Type","application/json");e.setRequestHeader("Authorization",Harmony._getAuthorisation());e.withCredentials=!0;e.onreadystatechange=function(a){4==e.readyState&&(200==e.status?(d=JSON.parse(e.responseText),d.payload||(d.payload=[]),c(d)):c({error:e.status}))};a=JSON.stringify(a);a.replace(/\\"/g,"'");e.send(a)}};
Harmony.Parse={address:function(b,a){Harmony._lookup("/parse/address",{fullAddress:b},null,a)}};
Harmony.Types={floorLevel:function(b,a){Harmony.Types._lookup("/floorLevel",b,a)},postal:function(b,a){Harmony.Types._lookup("/postal",b,a)},state:function(b,a){Harmony.Types._lookup("/state",b,a)},street:function(b,a){Harmony.Types._lookup("/street",b,a)},streetSuffix:function(b,a){Harmony.Types._lookup("/streetSuffix",b,a)},unitFlat:function(b,a){Harmony.Types._lookup("/unitFlat",b,a)},_lookup:function(b,a,c){Harmony._postJSON("/type"+b,{sourceOfTruth:a},c)}};
Harmony.Extensions={identify:function(b,a){Harmony._lookup("/identify",b,null,a)}};Harmony.Validate={address:function(b,a){Harmony._lookup("/validate/address",b,null,a)},email:function(b,a){Harmony._lookup("/validate/email",{address:b},null,a)},phone:function(b,a){Harmony._lookup("/validate/phone",b,null,a)}};
var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/\x3d",encode:function(b){var a="",c,d,e,f,k,h,l=0;for(b=Base64._utf8_encode(b);l<b.length;)c=b.charCodeAt(l++),d=b.charCodeAt(l++),e=b.charCodeAt(l++),f=c>>2,c=(c&3)<<4|d>>4,k=(d&15)<<2|e>>6,h=e&63,isNaN(d)?k=h=64:isNaN(e)&&(h=64),a=a+this._keyStr.charAt(f)+this._keyStr.charAt(c)+this._keyStr.charAt(k)+this._keyStr.charAt(h);return a},decode:function(b){var a="",c,d,e,f,k,h=0;for(b=b.replace(/[^A-Za-z0-9\+\/\=]/g,
"");h<b.length;)c=this._keyStr.indexOf(b.charAt(h++)),d=this._keyStr.indexOf(b.charAt(h++)),f=this._keyStr.indexOf(b.charAt(h++)),k=this._keyStr.indexOf(b.charAt(h++)),c=c<<2|d>>4,d=(d&15)<<4|f>>2,e=(f&3)<<6|k,a+=String.fromCharCode(c),64!=f&&(a+=String.fromCharCode(d)),64!=k&&(a+=String.fromCharCode(e));return a=Base64._utf8_decode(a)},_utf8_encode:function(b){b=b.replace(/\r\n/g,"\n");for(var a="",c=0;c<b.length;c++){var d=b.charCodeAt(c);128>d?a+=String.fromCharCode(d):(127<d&&2048>d?a+=String.fromCharCode(d>>
6|192):(a+=String.fromCharCode(d>>12|224),a+=String.fromCharCode(d>>6&63|128)),a+=String.fromCharCode(d&63|128))}return a},_utf8_decode:function(b){for(var a="",c=0,d=0,e=0,f;c<b.length;)d=b.charCodeAt(c),128>d?(a+=String.fromCharCode(d),c++):191<d&&224>d?(e=b.charCodeAt(c+1),a+=String.fromCharCode((d&31)<<6|e&63),c+=2):(e=b.charCodeAt(c+1),f=b.charCodeAt(c+2),a+=String.fromCharCode((d&15)<<12|(e&63)<<6|f&63),c+=3);return a}};"object"!==typeof JSON&&(JSON={});
(function(){function b(a){return 10>a?"0"+a:a}function a(a){e.lastIndex=0;return e.test(a)?'"'+a.replace(e,function(a){var b=h[a];return"string"===typeof b?b:"\\u"+("0000"+a.charCodeAt(0).toString(16)).slice(-4)})+'"':'"'+a+'"'}function c(b,d){var e,n=null,h,p,q=f,m,g=d[b];g&&"object"===typeof g&&"function"===typeof g.toJSON&&(g=g.toJSON(b));"function"===typeof l&&(g=l.call(d,b,g));switch(typeof g){case "string":return a(g);case "number":return isFinite(g)?String(g):"null";case "boolean":case "null":return String(g);
case "object":if(!g)return"null";f+=k;m=[];if("[object Array]"===Object.prototype.toString.apply(g)){p=g.length;for(e=0;e<p;e+=1)m[e]=c(e,g)||"null";h=0===m.length?"[]":f?"[\n"+f+m.join(",\n"+f)+"\n"+q+"]":"["+m.join(",")+"]";f=q;return h}if(l&&"object"===typeof l)for(p=l.length,e=0;e<p;e+=1)"string"===typeof l[e]&&(n=l[e],(h=c(n,g))&&m.push(a(n)+(f?": ":":")+h));else for(n in g)Object.prototype.hasOwnProperty.call(g,n)&&(h=c(n,g))&&m.push(a(n)+(f?": ":":")+h);h=0===m.length?"{}":f?"{\n"+f+m.join(",\n"+
f)+"\n"+q+"}":"{"+m.join(",")+"}";f=q;return h}}"function"!==typeof Date.prototype.toJSON&&(Date.prototype.toJSON=function(){return isFinite(this.valueOf())?this.getUTCFullYear()+"-"+b(this.getUTCMonth()+1)+"-"+b(this.getUTCDate())+"T"+b(this.getUTCHours())+":"+b(this.getUTCMinutes())+":"+b(this.getUTCSeconds())+"Z":null},String.prototype.toJSON=Number.prototype.toJSON=Boolean.prototype.toJSON=function(){return this.valueOf()});var d=/[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
e=/[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,f="",k="",h={"\b":"\\b","\t":"\\t","\n":"\\n","\f":"\\f","\r":"\\r",'"':'\\"',"\\":"\\\\"},l=null;"function"!==typeof JSON.stringify&&(JSON.stringify=function(a,b,d){var e;k=f="";if("number"===typeof d)for(e=0;e<d;e+=1)k+=" ";else"string"===typeof d&&(k=d);if((l=b)&&"function"!==typeof b&&("object"!==typeof b||"number"!==typeof b.length))throw Error("JSON.stringify");return c("",
{"":a})});"function"!==typeof JSON.parse&&(JSON.parse=function(a,b){function c(a,e){var d=null,f,g=a[e];if(g&&"object"===typeof g)for(d in g)Object.prototype.hasOwnProperty.call(g,d)&&(f=c(g,d),void 0!==f?g[d]=f:delete g[d]);return b.call(a,e,g)}var e;a=String(a);d.lastIndex=0;d.test(a)&&(a=a.replace(d,function(a){return"\\u"+("0000"+a.charCodeAt(0).toString(16)).slice(-4)}));if(/^[\],:{}\s]*$/.test(a.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g,"@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
"]").replace(/(?:^|:|,)(?:\s*\[)+/g,"")))return e=eval("("+a+")"),"function"===typeof b?c({"":e},""):e;throw new SyntaxError("JSON.parse");})})();